<template></template>

<script>
const axios = require('axios');

export default {
    beforeCreate() {
        console.log('Got auth')
        axios.get(`${process.env.VUE_APP_APIURL}/genstate/`)
            .then(response => {
                console.log('Got auth2')
                localStorage.setItem('oauth-state', Buffer.from(response.data, 'base64').toString('ascii'));
                window.location.href = `${process.env.VUE_APP_AUTHURL}` + '&state=' + response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
}
</script>